'use client';
import React, { useRef, useState, useEffect } from 'react';
import { Button, Box, CircularProgress } from '@mui/material';
import { Mic, MicOff } from '@mui/icons-material';

const AudioRecorder = ({ onAudioRecorded }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordedUrl, setRecordedUrl] = useState('');
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaStream = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const timerRef = useRef(null);

  // Effect for recording timer
  useEffect(() => {
    if (isRecording) {
      timerRef.current = setInterval(() => {
        setRecordingTime(prev => prev + 1);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
      setRecordingTime(0);
    }
    
    return () => clearInterval(timerRef.current);
  }, [isRecording]);

  const startRecording = async () => {
    try {
      // Ask for microphone permission
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaStream.current = stream;
      
      // Create a new MediaRecorder
      mediaRecorder.current = new MediaRecorder(stream);
      
      // When new data is available, push it into chunks array
      mediaRecorder.current.ondataavailable = (e) => {
        if (e.data.size > 0) {
          chunks.current.push(e.data);
        }
      };
      
      // When recording stops, create a Blob from chunks
      mediaRecorder.current.onstop = () => {
        const recordedBlob = new Blob(chunks.current, { type: 'audio/webm' });
        chunks.current = [];
        
        // Create a temporary URL to listen to playback in the browser
        const url = URL.createObjectURL(recordedBlob);
        setRecordedUrl(url);
        
        // Send the raw Blob back to the parent (for analysis/upload)
        if (onAudioRecorded) {
          onAudioRecorded(recordedBlob);
        }
      };
      
      // Start recording
      mediaRecorder.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error('Error accessing microphone:', error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === 'recording') {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
    
    if (mediaStream.current) {
      mediaStream.current.getTracks().forEach((track) => track.stop());
    }
  };

  // Format time as MM:SS
  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const notionGrey = '#202020';
  const magenta = '#FF00FF';
  const offWhite = '#CFCFCF';
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
      {recordedUrl && (
        <audio 
          controls 
          src={recordedUrl} 
          style={{ 
            width: '100%', 
            borderRadius: '4px',
            backgroundColor: notionGrey,
            color: offWhite 
          }} 
        />
      )}
      
      <Box sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: isRecording ? magenta : notionGrey,
              color: offWhite,
              borderRadius: '50%',
              width: '44px',
              height: '44px',
              minWidth: 'unset',
              transition: 'all 0.3s ease',
              '&:hover': {
                backgroundColor: isRecording ? '#D100D1' : '#2c2c2c',
                transform: 'scale(1.05)'
              },
              position: 'relative',
              zIndex: 1
            }}
            onClick={isRecording ? stopRecording : startRecording}
          >
            {isRecording ? <MicOff /> : <Mic />}
          </Button>
          
          {isRecording && (
            <CircularProgress
              size={52}
              thickness={2}
              sx={{
                color: magenta,
                position: 'absolute',
                top: -4,
                left: -4,
                zIndex: 0,
                animation: 'pulse 1.5s ease-in-out infinite'
              }}
            />
          )}
        </Box>
        
        {isRecording && (
          <Box
            sx={{
              color: magenta,
              fontWeight: 'bold',
              marginLeft: '16px',
              '@keyframes pulse': {
                '0%': { opacity: 0.6 },
                '50%': { opacity: 1 },
                '100%': { opacity: 0.6 }
              },
              animation: 'pulse 1.5s ease-in-out infinite'
            }}
          >
            {formatTime(recordingTime)}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AudioRecorder;