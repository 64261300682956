import React, { useState, useCallback, useEffect, useRef, useImperativeHandle, forwardRef, useMemo } from 'react';
import { createEditor, Editor, Range, Text, Transforms, Path, last } from 'slate';
import { Slate, Editable, withReact, useSlate, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history'
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button, TextField, IconButton, Typography } from '@mui/material';
import ReloadIcon from '@mui/icons-material/Replay';
import AddIcon from '@mui/icons-material/Add';
import MenuIcon from '@mui/icons-material/Menu';
import '@fontsource/oswald';
import '@fontsource/oswald/200.css';
import { darkTheme, editorStyle, notionGrey, boxStyle, offWhite, notionBlack } from './cowritertheme';
import { isCursorAtEndOfLine, clearEditorContent, getLinePosition, replaceEditorContent, isCursorBehindLastWord,getLastWordofLine, isLastRhymeNext, fetchRhymes, handleAddRhyme, addAILine, setCursorPosition,addParagraph,replaceParagraphText,deleteParagraph ,getBoxPosition, getParagraphText} from './cwhelper';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios'; // To make HTTP requests
import SaveIcon from '@mui/icons-material/Save';
import CreateIcon from '@mui/icons-material/Create';
import useViewportSize from './useViewportSize';
import useIsOnScreenKeyboardOpen from './useIsOnScreenKeyboardOpen';




const initialValue = [
  {
    type: 'paragraph',
    children: [{ text: "" }],
  },
];

const SlateEditorContent = forwardRef(({ textcontent,setTextcontent,randInt, setRandInt, currentDocumentId, setCurrentDocumentId }, ref) => {
  const editor = useSlate();
  const lastWordRef = useRef('');
  const isTypingRef = useRef(false);
  const typingTimeoutRef = useRef(null);
  const lastSelectionRef = useRef(null);
  const selectionChangeRef = useRef(false);
  const boxPositiontop = useRef(0);
  const boxPositionleft = useRef(0);
  const paragraphPosition = useRef(0);
  const [showBox, setShowBox] = useState(false);
  const [rhymes, setRhymes] = useState([]);
  const [rhymeIndex, setRhymeIndex] = useState(0);
  const [lastsetrhyme, setLastsetrhyme] = useState(null);
  const [aiSuggestions, setAiSuggestions] = useState([]);
  const aiParagraph = useRef(null);
  const [aiSuggestionIndex, setAiSuggestionIndex] = useState(0);
  const [isPasting, setIsPasting] = useState(false);
  const [hasClicked, sethasClicked]= useState(false);
  const [haspressedReturn, sethaspressedReturn]= useState(false);
  const [showAiBox, setShowAiBox] = useState(false);
  const currentParagraph = useRef(-1);
  const tempparagraph = useRef(-1);
  const isGenerating = useRef(false);
  const [isThinking, setIsThinking] = useState(false);
  const [, forceUpdate] = useState({});
  const editorRef = useRef(null);


    function injectManifestLink() {
      // Check if a manifest link already exists and remove it
      const existingLink = document.querySelector('link[rel="manifest"]');
      if (existingLink) {
        existingLink.remove();
      }
      
    
      // Create a new link element for the manifest
      const manifestLink = document.createElement('link');
      manifestLink.rel = 'manifest';
      manifestLink.href = '/manifests/cowriter/manifest.json';
      
      // Add the link to the document head
      document.head.appendChild(manifestLink);
    }
     useEffect(() => {
    injectManifestLink();
  }, []);



  useImperativeHandle(ref, () => ({
    createnewDocument,
    fetchAiLine,
    printSomething,
    loadContent,
  }));

  function loadContent() {
    if (tempparagraph.current != -1 && getParagraphText(editor, tempparagraph.current) === "")
      {
        deleteParagraph(editor, tempparagraph.current);
        tempparagraph.current = -1;
      }


      setShowBox(false); 
      setShowAiBox(false); 

    currentParagraph.current = -1;
    aiParagraph.current = -1;
    tempparagraph.current = -1; 
    replaceEditorContent(editor, textcontent);
  }

  function createnewDocument() {

    if (tempparagraph.current != -1 && getParagraphText(editor, tempparagraph.current) === "")
      {
        deleteParagraph(editor, tempparagraph.current);
        tempparagraph.current = -1;
      }


      setShowBox(false); 
      setShowAiBox(false); 

    currentParagraph.current = -1;
    aiParagraph.current = -1;
    tempparagraph.current = -1; 
    clearEditorContent(editor);
  } 




  
  
  const fetchAiLine = async (promptcontext) => {

    if (tempparagraph.current != -1 && getParagraphText(editor, tempparagraph.current) === "")
      {
        deleteParagraph(editor, tempparagraph.current);
        tempparagraph.current = -1;
      }

      setIsThinking(true);
      setShowBox(false); 
      setShowAiBox(false); 

      console.log(promptcontext);

    const suggestions = await addAILine(editor,currentParagraph.current,promptcontext);
    
    isGenerating.current = true;

    if (suggestions && suggestions.length > 0) {
     
      setAiSuggestions(suggestions);
      setAiSuggestionIndex(0);
     //console.log('currentparagraph '+currentParagraph.current);

      let newaiparagraph = 0;

      if (getParagraphText(editor, currentParagraph.current) === "")
      {
        newaiparagraph = currentParagraph.current;
      }
      else
      {
        newaiparagraph = currentParagraph.current + 1;
      }
      
     //console.log('np '+newaiparagraph);
      
        aiParagraph.current = newaiparagraph;
      
      
     //console.log('aiparagraph '+aiParagraph.current);
      addParagraph(editor, aiParagraph.current, suggestions[0]);
      setShowBox(false); // Hide the rhyme box when AI suggestion
      if (tempparagraph.current != -1 && getParagraphText(editor, tempparagraph.current) === "")
      {
        deleteParagraph(editor, tempparagraph.current);
        tempparagraph.current = -1;
      }

      setTimeout(() => {
      let newboxPosition = getBoxPosition(editor,aiParagraph.current);
              boxPositiontop.current = newboxPosition.y -5;
              boxPositionleft.current = newboxPosition.x;
      setShowAiBox(true);
      isGenerating.current = false;
      setIsThinking(false);
      }, 50);
    }
  };


  const handleReloadAiSuggestion = () => {
    const nextIndex = (aiSuggestionIndex + 1) % aiSuggestions.length;
    setAiSuggestionIndex(nextIndex);
    replaceParagraphText(editor, aiParagraph.current, aiSuggestions[nextIndex]);
  };

  // Handle accept (leave the paragraph and close the box)
  const handleAcceptAiSuggestion = () => {
    setShowAiBox(false);
    setAiSuggestions([]);
    setAiSuggestionIndex(0);
    addParagraph(editor, aiParagraph.current + 1, "");   
    

    

    setCursorPosition(editor, aiParagraph.current +1, 0);
    setParagraphPosition(aiParagraph.current +1);
    currentParagraph.current = aiParagraph.current +1;
    aiParagraph.current = -1;

    if (editorRef.current) {
      ReactEditor.focus(editorRef.current);
    }


  };

  // Handle cancel (remove the paragraph and close the box)
  const handleCancelAiSuggestion = () => {
    if (aiParagraph.current != -1)
      {
        deleteParagraph(editor, aiParagraph.current);
        aiParagraph.current = -1;
      }
    setShowAiBox(false);
    setAiSuggestions([]);
    setAiSuggestionIndex(0);
    aiParagraph.current = -1;
  };

  const printSomething = () => {
   //console.log('Something');
   //console.log(textcontent);
  };

  const generateRandomNumber = () => {
    

      if (editor.children)
      {
      const textlenght = editor.children[0].children[0].text.length;
     
      
      if (textlenght > 0)
      {
        return Math.floor(1000 + Math.random() * 9000);
      }
      else
      {

        return 0;
      }
      
      }
  
  };

  
  
  const handleDoubleClick = (event) => {
    // Get the current selection
    const selection = window.getSelection();
    
    if (selection) {
      const selectedText = selection.toString().trim();
      const lastofline = getLastWordofLine(editor, currentParagraph.current);
      if (selectedText.length > 0 && selectedText === lastofline) {
        getRhymes(selectedText);
      }
    }
  };

  const handleClick = (event) => {
   

    sethasClicked(true);
    setTimeout(() => {
      sethasClicked(false);
    }, 0); // Reset after the paste completes


    if (tempparagraph.current !== -1 && getParagraphText(editor, tempparagraph.current) === "") {
      deleteParagraph(editor, tempparagraph.current);
      tempparagraph.current = -1;
    }

    setShowBox(false);

   
  };

  async function getRhymes(lastWord){

    if (tempparagraph.current !== -1 && getParagraphText(editor, tempparagraph.current) === "") {
      deleteParagraph(editor, tempparagraph.current);
      tempparagraph.current = -1;
    }

    setShowBox(false); 
    setShowAiBox(false); 

    const fetchedRhymes = await fetchRhymes(lastWord);
    if (fetchedRhymes.length > 0) {
      setRhymes(fetchedRhymes);
      setRhymeIndex(0);
      tempparagraph.current = currentParagraph.current + 1;
     //console.log('tempparagraph '+tempparagraph.current);
      addParagraph(editor, tempparagraph.current, "");
      let newboxPosition = getBoxPosition(editor, tempparagraph.current -1);
      boxPositiontop.current = newboxPosition.y + 25;
      //boxPositionleft.current = newboxPosition.x;
      setShowBox(true);
    } else {
      setShowBox(false);

      if (tempparagraph.current !== -1 && getParagraphText(editor, tempparagraph.current) === ""){
        deleteParagraph(editor, tempparagraph.current);
        tempparagraph.current = -1;
      }
    }
    lastWordRef.current = lastWord;


  }
  

  useEffect(() => {
    const updateCurrentParagraph = () => {
      const { selection } = editor;
      if (selection) {
        const [match] = Editor.nodes(editor, {
          at: selection.focus,
          match: n => n.type === 'paragraph',
        });
        currentParagraph.current = match ? match[1][0] : -1;
    
        setParagraphPosition(currentParagraph.current);
        
      } else {
        currentParagraph.current = -1;
      }
    };
  
    // Update on any selection change
    updateCurrentParagraph();

    setTextcontent(editor);
    setRandInt(generateRandomNumber());
   
    
    // Handle any interaction that might change the selection
    const handleInteraction = () => {
      // Small delay to ensure the selection has been updated
      setTimeout(updateCurrentParagraph, 0);
    };
   

    // Listen for mousedown and keydown events
    document.addEventListener('mousedown', handleInteraction);
    document.addEventListener('keydown', handleInteraction);

    

    if (
      !isPasting &&
      !isGenerating.current &&
      !hasClicked &&
      !haspressedReturn &&
      editor.selection &&
      (!lastSelectionRef.current || !Range.equals(editor.selection, lastSelectionRef.current))
    ) {
      if (selectionChangeRef.current) {
        selectionChangeRef.current = false;
        return;
      }
  
      
      lastSelectionRef.current = editor.selection;
  
      if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
  
      const { status, lastWord } = isCursorBehindLastWord(editor, currentParagraph.current);
      if (!status) return;
  
      typingTimeoutRef.current = setTimeout(() => {
        isTypingRef.current = false;
        if (lastWord && lastWord !== lastWordRef.current && lastWord.length > 1) {
          getRhymes(lastWord);
        }
      }, 800);
  
      isTypingRef.current = true;
    }

    
  
    return () => {
      document.removeEventListener('mousedown', handleInteraction);
      document.removeEventListener('keydown', handleInteraction);
      if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
    };
  }, [editor.selection]);

  useEffect(() => {
    const handleMouseDown = () => {
      selectionChangeRef.current = true;
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, []);

  const handleReloadRhyme = () => {
    setRhymeIndex((prevIndex) => (prevIndex + 1) % rhymes.length);
  };

  const handleAddRhymeWrapper = () => {

    if (tempparagraph.current != -1 && getParagraphText(editor, tempparagraph.current) === "")
      {
        deleteParagraph(editor, tempparagraph.current);
        tempparagraph.current = -1;
      }
    handleAddRhyme(editor, rhymes, rhymeIndex, setLastsetrhyme, setShowBox, currentParagraph.current);
  };

  useEffect(() => {
    return () => {
      if (typingTimeoutRef.current) {
        clearTimeout(typingTimeoutRef.current);
      }
    };
  }, []);

  const preventBlur = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleInteraction = useCallback((action) => {
    return (e) => {
      e.preventDefault();
      e.stopPropagation();
      action();
      // Refocus the editor after the action
      if (editorRef.current) {
        ReactEditor.focus(editorRef.current);
      }
    };
  }, []);

  const boxProps = {
    onMouseDown: preventBlur,
    onTouchStart: preventBlur,
    onMouseUp: preventBlur,
    onTouchEnd: preventBlur,
    style: { userSelect: 'none', touchAction: 'none' },
  };

  const buttonProps = (action) => ({
    onMouseDown: handleInteraction(action),
    onTouchStart: handleInteraction(action),
    size: "small",
    sx: { color: 'white' },
  });

  useEffect(() => {
    if (showAiBox) {
      const { selection } = editor;
      setShowBox(false); // Hide the rhyme box when AI suggestion
      if (tempparagraph.current != -1 && getParagraphText(editor, tempparagraph.current) === "")
      {
        deleteParagraph(editor, tempparagraph.current);
        tempparagraph.current = -1;
      }
     //console.log('aiparagraph '+aiParagraph.current);
      let newboxPosition = getBoxPosition(editor,aiParagraph.current);
              boxPositiontop.current = newboxPosition.y -5;
              boxPositionleft.current = newboxPosition.x;
    }
  }, [showAiBox,aiSuggestionIndex]);

  const handleKeyDown = (event) => {

    if (event.key) {

      if (tempparagraph.current != -1 && getParagraphText(editor, tempparagraph.current) === "")
        {
          setShowBox(false);
          deleteParagraph(editor, tempparagraph.current);
          setShowBox(false);
          tempparagraph.current = -1;
        }

    }

    if (event.key === 'Enter' || event.key === 'Backspace' || event.key === 'Delete') {
      setShowBox(false); // Hide the box when Enter is pressed
      setShowAiBox(false);
      if (tempparagraph.current != -1 && getParagraphText(editor, tempparagraph.current) === "")
      {
        deleteParagraph(editor, tempparagraph.current);
        setShowBox(false);
        tempparagraph.current = -1;
      }
    }
    if (event.key === 'Backspace') {
     //console.log('haspressedreturn');
      sethaspressedReturn(true);
      setTimeout(() => {
        sethaspressedReturn(false);
      }, 0); // Reset after the paste completes
      
    }

    
    if (event.key === 'Enter' && isLastRhymeNext(editor , lastsetrhyme))
    {
      event.preventDefault(); // Prevent default Enter behavior
     //console.log('added life from event key')
      addParagraph(editor, currentParagraph.current + 1, "");
      setCursorPosition(editor, currentParagraph.current + 1, 0);
      setLastsetrhyme(null);
    }
  };

  const handlePaste = (event) => {
    setIsPasting(true);
    setTimeout(() => {
      setIsPasting(false);
    }, 50); // Reset after the paste completes
  };


  function setParagraphPosition(paragraph) {
//console.log(paragraph);
    
    if (paragraph != -1) {
   
      
    //paragraphPosition.current = getLinePosition(editor, paragraph);
  
    //forceUpdate({}); // This triggers a re-render
   
  }

  }

  return (
    <>
      <Editable
        className="slate-editor"
        style={editorStyle.slateEditor}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onDoubleClick={handleDoubleClick}
        onClick={handleClick}
        placeholder="Let's write a song..."
      />
{isThinking && (      
  <Box sx={{ display:'flex',background: notionBlack, position:'absolute', width:'100%', height:'100%',top:0,left:0, zIndex:110, justifyContent:'center', alignContent:'center', alignItems:'center'}}>
    <Typography variant="h6" sx={{ textAlign: 'center', }}>Thinking...</Typography>
  </Box>
)}

{showBox && (
        <Box
          {...boxProps}
          sx={{
            ...boxStyle.rhymebox,
            top: boxPositiontop.current,
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
            height: '1.5em',
            borderRadius: '4px',
            backgroundColor: '#333',
          }}
        >
          <div style={{ textAlign: 'right', flexGrow: 1, marginRight: '10px', color: 'white' }}>
            {rhymes[rhymeIndex]}
          </div>
          {rhymes.length > 1 && (
            <IconButton
              {...buttonProps(handleReloadRhyme)}
              sx={{ marginLeft: 'auto' }}
            >
              <ReloadIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton
            {...buttonProps(handleAddRhymeWrapper)}
            sx={{ marginLeft: '5px', marginRight: '5px' }}
          >
            <AddIcon fontSize="small" />
          </IconButton>
        </Box>
      )}

      {showAiBox && (
        <Box
          {...boxProps}
          sx={{
            ...boxStyle.aiBox,
            top: {xs: boxPositiontop.current + 30, sm: boxPositiontop.current + 30},
            display: 'flex',
            alignItems: 'center',
            padding: '5px',
            borderRadius: '4px',
            backgroundColor: '#333',
          }}
        >
          <IconButton
            {...buttonProps(handleReloadAiSuggestion)}
            sx={{ marginLeft: 'auto' }}
          >
            <ReloadIcon fontSize="small" />
          </IconButton>
          <IconButton
            {...buttonProps(handleAcceptAiSuggestion)}
            sx={{ marginLeft: '5px' }}
          >
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton
            {...buttonProps(handleCancelAiSuggestion)}
            sx={{ marginLeft: '5px', marginRight: '5px' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
      )}
    </>
  );
});

export default function SlateEditor() {
  const [editor] = useState(() => withReact(withHistory(createEditor())))
  const editorContentRef = useRef();
  const [isPWA] = useState(
    window.matchMedia('(display-mode: standalone)').matches 
    || window.navigator.standalone 
    || document.referrer.includes('android-app://')
  );
  const [textcontent, setTextcontent] = useState(initialValue);
  const [randInt,setRandInt] = useState(null);
  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [archiveddocuments, setarchivedDocuments] = useState([]);
  const [title, setTitle] = useState('New Song');
  const [promptcontext, setPromptcontext] = useState(''); // New state for the title
  const randomnumber = useRef(0);
  const lastrandomnumber = useRef(0);
  const currentcontent = useRef(initialValue);
  const currentdocid = useRef(null);
  const currentitle = useRef(null);
  const [archiveVisible, setarchiveVisible] = useState(false);
  const [optionsVisible, setoptionsVisible] = useState(false);
  const editorBoxRef = useRef(null);
  const [editorHeight, setEditorHeight] = useState('calc(100vh - 190px)');
  const [ismini, setismini] = useState(false);
  const [initialViewportHeight, setInitialViewportHeight] = useState(0);
  const [isContextFocused, setIsContextFocused] = useState(false);
  const [isTitleFocused, setIsTitleFocused] = useState(false);

  const viewportSize = useViewportSize();
  const isKeyboardOpen = useIsOnScreenKeyboardOpen();

  useEffect(() => {
    if (!viewportSize) return;

    const [viewportWidth, viewportHeight] = viewportSize;

    const handleResize = () => {
    
      if (editorBoxRef.current && isContextFocused == false && isTitleFocused == false) {
        if (isKeyboardOpen && viewportWidth < 600) {
          // Keyboard is open on a mobile device
          const newHeight = viewportHeight-20; // Minimal padding when keyboard is visible
          setEditorHeight(`${newHeight}px`);
          setismini(true);
        } else {
          // Keyboard is not open or it's not a mobile device
          const newHeight = viewportHeight - 220; // 220px for bottom elements
          setEditorHeight(`${newHeight}px`);
          setismini(false);
        }
      }
    };

    handleResize();

    // Set up a timeout to call handleResize again after a short delay
    const timeoutId = setTimeout(handleResize, 100);

    return () => clearTimeout(timeoutId);
  }, [viewportSize, isKeyboardOpen]);


  const handleContextFocus = () => {
    setIsContextFocused(true);
   
  };

  const handleContextBlur = () => {
    setIsContextFocused(false);
   
  };

  const handleTitleFocus = () => {
    setIsTitleFocused(true);
    
  };

  const handleTitleBlur = () => {
    setIsTitleFocused(false);
   
  };

  useEffect(() => {

     
    randomnumber.current = randInt;
    currentcontent.current = textcontent;
    

  }, [randInt]);


  useEffect(() => {

    currentitle.current = title;

  }, [title]);

  useEffect(() => {

    currentdocid.current = currentDocumentId;

  }, [currentDocumentId]);


  useEffect(() => {
    // Set up an interval to check every 10 seconds
    const intervalId = setInterval(() => {

      
      if (randomnumber.current != 0)
      {

      if (randomnumber.current != lastrandomnumber.current)
      {
       
        lastrandomnumber.current = randomnumber.current;
       
        handleSaveDocument();
       
      
      }
     
    }
      



    }, 30000); // 10,000 milliseconds = 10 seconds

    // Cleanup interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);


  function backtohome() { 



    if (randomnumber.current != 0)
      {

      if (randomnumber.current != lastrandomnumber.current)
      {
        
        lastrandomnumber.current = randomnumber.current;
       
        handleSaveDocument();

        setTimeout(() => {  
        
        window.location.href = '/';

        }, 1000);
       
      
      }
      else
      {
        window.location.href = '/';
      }

    }
    else
    {
      window.location.href = '/';
    }
    
    


  }

  function toggleArchive() {
 
   setarchiveVisible(!archiveVisible);
  }


  function toggleOptions() {
 
    setoptionsVisible(!optionsVisible);
   }


  async function fetchDocuments() {
    const userString = localStorage.getItem('user');
    let userEmail = '';
    let userName = '';

    if (userString) {
      const userData = JSON.parse(userString);
      userEmail = userData.email || '';
      userName = userData.displayName || '';
    } else {
      console.error('No user email found in localStorage');
      return;
    }

    try {
      const response = await axios.get(`https://serve.philspeiser.com/api/cwgetdocuments?email=${userEmail}&name=${userName}`);

      const filteredData = response.data.filter(doc => doc.is_deleted === false);
      const filteredData2 = response.data.filter(doc => doc.is_deleted === true);
      // Sort the filtered data by updated_at in descending order
      const sortedData = filteredData.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });
      const sortedData2 = filteredData2.sort((a, b) => {
        return new Date(b.updated_at) - new Date(a.updated_at);
      });

      setDocuments(sortedData);
      setarchivedDocuments(sortedData2);
     //console.log('Documents fetched and sorted successfully:', sortedData);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }


  useEffect(() => {
    

    fetchDocuments();
  }, []);

  const handleLoadDocument = (doc) => {
    const parsedContent = JSON.parse(doc.content);
    setTitle(doc.title);
    setCurrentDocumentId(doc.id);
    setTextcontent(parsedContent); // Update the editor's content
    
    setTimeout(() => {
    if (editorContentRef.current) {
      editorContentRef.current.loadContent();
      setoptionsVisible(false);
    }
  }, 250);

  };

  const handleSaveDocument = async () => {
    const userString = localStorage.getItem('user');
    let userEmail = '';
    let userName = '';

   //console.log(textcontent);

    if (userString) {
      const userData = JSON.parse(userString);
      userEmail = userData.email || '';
      userName = userData.displayName || '';
    } else {
      console.error('No user email found in localStorage');
      return;
    }

    if (!currentcontent.current) {
      console.error('No content to save');
      return;
    }

    try {
      const payload = {
        email: userEmail,
        name: userName,
        documentTitle: currentitle.current,
        document: currentcontent.current, 
        is_deleted: false,
        ...(currentdocid.current && { documentId: currentdocid.current}),
      };

      const response = await axios.post('https://serve.philspeiser.com/api/cwsavedocument', payload);
     //console.log('Document saved successfully:', response.data);

      if (response.data.id) {
        setCurrentDocumentId(response.data.id);
      }

      fetchDocuments();

    } catch (error) {
      console.error('Error saving document:', error);
    }
  };


  const handleDeleteDocument = async (doctodelete) => {
    const userString = localStorage.getItem('user');
    let userEmail = '';
    let userName = '';


    if (userString) {
      const userData = JSON.parse(userString);
      userEmail = userData.email || '';
      userName = userData.displayName || '';
    } else {
      console.error('No user email found in localStorage');
      return;
    }

    try {
      const payload = {
        email: userEmail,
        name: userName,
        documentId: doctodelete.id,
      };

      const response = await axios.post('https://serve.philspeiser.com/api/cwarchivedocument', payload);
     //console.log('Document saved successfully:', response.data);


      fetchDocuments();

    } catch (error) {
      console.error('Error saving document:', error);
    }
  };


  const handleRestoreDocument = async (doctorestore) => {
    const userString = localStorage.getItem('user');
    let userEmail = '';
    let userName = '';


    if (userString) {
      const userData = JSON.parse(userString);
      userEmail = userData.email || '';
      userName = userData.displayName || '';
    } else {
      console.error('No user email found in localStorage');
      return;
    }

    try {
      const payload = {
        email: userEmail,
        name: userName,
        documentId: doctorestore.id,
      };

      const response = await axios.post('https://serve.philspeiser.com/api/cwrestoredocument', payload);
     //console.log('Document saved successfully:', response.data);


      fetchDocuments();

    } catch (error) {
      console.error('Error saving document:', error);
    }
  };

  function print() {    
   //console.log(textcontent);
  }

  function handleNewDocument() {
    setCurrentDocumentId(null);
    setTitle('New Song');

   //console.log(currentDocumentId);

    if (editorContentRef.current) {
      editorContentRef.current.createnewDocument();
    }
  } 

  const handleFetchAiLine = () => {
    if (editorContentRef.current) {
      editorContentRef.current.fetchAiLine(promptcontext);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
  
      <Box
        ref={editorBoxRef}
        sx={{
          width: '100%',
          p: 0,
          backgroundColor: notionGrey,
          maxWidth: '1200px',
          margin: '0 auto',
          overflow: 'auto',
          height: editorHeight,
          transition: 'height 0.1s',
          zIndex:50,
          
        }}
      >
        
        <Slate 
          editor={editor} 
          initialValue={initialValue} 
          
        >
          <SlateEditorContent
            ref={editorContentRef}
            textcontent={textcontent}
            setTextcontent={setTextcontent}
            randInt={randInt}
            setRandInt={setRandInt}
            currentDocumentId={currentDocumentId}
            setCurrentDocumentId={setCurrentDocumentId}
            
          />
        </Slate>

        
      </Box>
    
      {ismini && (
      <Box sx={{height:'20px',background:notionGrey}}>  </Box>
      )}
      
      {!ismini && (
  <> 

      <Box
        sx={{
          mt: 2,
          mb: 4,
          textAlign: 'center',
          position: 'sticky',
          bottom: '120px',
          zIndex: 50,
          background:notionBlack,
          height: '100px'
        }}
      >
         



      <TextField
  value={promptcontext}
  onChange={(e) => setPromptcontext(e.target.value)}
  onFocus={handleContextFocus}
  onBlur={handleContextBlur}
  placeholder="context (optional)"
  variant="standard"
  fullWidth
  multiline
  minRows={1} // Adjust as needed for minimum height
  sx={{
    mb: 0,
    p: 1,
    textarea: {
      color: offWhite,
      fontSize: '1.0rem',
      letterSpacing: '0.1em',
      fontFamily: 'monospace',
      fontWeight: 'normal',
      textAlign: 'center', // Center-aligns the text
    },
  }}
  InputProps={{
    disableUnderline: true,
  }}
/>
        <Button
          onClick={handleFetchAiLine}
          variant="contained"
          sx={{
            color: 'white',
            backgroundColor: notionGrey,
            marginRight: '0px',
          }}
        >
          Suggest Next Line
        </Button>

    
    <Box sx={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '10px' }}>

        <Box
  sx={{
    mt: 2,
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#222',
    justifyContent: 'center',
    position: 'fixed',
    height: '40px',
    bottom: '60px',
    width: '100%', // Ensures the content spans the full width
    maxWidth: '1200px', // Limits the width of the content
    margin  : '0 auto',
  }}
>
  <TextField
    value={title}
    onChange={(e) => setTitle(e.target.value)}
    variant="standard"
    fullWidth
    onFocus={handleTitleFocus}
  onBlur={handleTitleBlur}
    sx={{
      mb: 0,
      p: 0,
      flexGrow: 1, // Allows the TextField to take up remaining space
      input: {
        color: offWhite,
        fontSize: '1.0rem',
        letterSpacing: '0.1em',
        fontFamily: 'monospace',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingLeft: { xs: '0px', sm: '0px' },
        '&::before': {
          borderBottom: 'none',
        },
        '&::after': {
          borderBottom: 'none',
        },
        '&:hover:not(.Mui-disabled)::before': {
          borderBottom: 'none',
        },
      },
    }}
    InputProps={{
      disableUnderline: true,
    }}
  />

  <Button
    onClick={handleSaveDocument}
    variant="contained"
    sx={{
      color: offWhite,
      minWidth: '30px',
      height: '30px',
      backgroundColor: notionGrey,
      marginRight: '10px',
    }}
    title="Save Song"
  >
    <SaveIcon />
  </Button>

  <Button
    onClick={handleNewDocument}
    variant="contained"
    sx={{
      color: offWhite,
      minWidth: '30px',
      height: '30px',
      backgroundColor: notionGrey,
      marginRight: '10px',
    }}
     title="Create New Song"
  >
    <CreateIcon />
  </Button>
</Box>


      
      </Box> 
      
     
      
      </Box>

      </>
)}  
      {optionsVisible && (
      <>

       <Box sx={{display:'flex',justifyContent: 'center'}}>  
       <Box sx={{  position: 'absolute',top: 0, zIndex:'100', background: notionBlack, p:4, minHeight:'100vh',maxWidth:'1200px', paddingBottom:'200px',    width: '100%'}}>
      

      


      
     
     


      {documents.length > 0 && (
  <>
    <Typography variant="h6" sx={{ mt: 4, textAlign: 'left',  paddingLeft:{xs: '20px',sm: '0px'} }}>Your Songs</Typography>
    {/* Document Previews */}
    <Box sx={{ mt: 1, display: 'flex', gap: '20px' , flexWrap:'wrap', p:{xs: '20px',sm: '0px'}}}>
      {documents.map((doc) => (
        <Box
          key={doc.id}
          sx={{
            mb: 1,
            p: 2,
            border: 'none',
            backgroundColor: notionGrey,
            borderRadius: '8px',
            width:{xs: '100%',sm: '250px'},
            minWidth: '250px',
            minHeight: '300px',

          }}
        >
          <p style={{ fontWeight: 800, fontSize: '1.2em', marginBottom: '0.1em', marginTop: '0.0em' }}>{doc.title.length > 30 ? doc.title.slice(0, 30) + '...' : doc.title}</p>
          <p style={{ fontWeight: 200, fontSize: '0.9em', marginTop: 0, marginBottom: '1.0em', minHeight:'140px'}}>
            {JSON.parse(doc.content)?.children
              ?.map((paragraph) => paragraph.children?.[0]?.text)
              .join(' ')
              .split(' ')
              .slice(0, 50)
              .join(' ') + ' ...' || 'No content available'}
          </p>
          <p style={{ fontSize: '0.8em', marginTop: 0, marginBottom: 0,fontWeight:'200' }}>
            <strong>Created: </strong> {new Date(doc.created_at).toLocaleString()}
          </p>
          <p style={{ fontSize: '0.8em', marginTop: 0, marginBottom: '1.0em' ,fontWeight:'200' }}>
            <strong>Updated: </strong> {new Date(doc.updated_at).toLocaleString()}
          </p>
          <Box sx={{ display: 'flex' }}>
          <Button
            onClick={() => handleLoadDocument(doc)}
            variant="contained"
            sx={{ color: 'white', backgroundColor: notionBlack, marginTop: '10px' ,marginRight:'10px'}}
          >
            Load
          </Button>
          <Button
            onClick={() => handleDeleteDocument(doc)}
            variant="contained"
            sx={{ color: 'white', backgroundColor: notionBlack, marginTop: '10px' }}
          >
            Archive
          </Button>
        </Box>
        </Box>
      ))}
    </Box>
  </>
)}

      
      {archiveddocuments.length > 0 && (
  <>
    <Box sx={{ display:'flex', alignContent:'center', alignItems: 'center', mt: 4, mb: 4,height: '1.0em', p:{xs: '20px',sm: '0px'}}}>
    <Typography variant="h6" sx={{textAlign: 'left',marginTop: '0px',marginBottom: '0px', padding: 0}}>Archive</Typography>
    <Button
            onClick={() => toggleArchive()}
            variant="contained"
            sx={{ color: 'white', backgroundColor: notionBlack, marginTop: '0px',marginBottom: '0px', marginLeft: '10px' }}
          >
            {archiveVisible ? 'Hide Archive' : 'Show Archive'}
          </Button>
          </Box>
    
    {archiveVisible && (
    <>
    <Box sx={{ mt: {xs: '0px',sm: 1}, p:{xs: '20px',sm: '0px'}}}>
      {archiveddocuments.map((doc) => (
        <Box
          key={doc.id}
          sx={{
            display: 'flex',
            mb: 1,
            p: 2,
            border: 'none',
            backgroundColor: notionGrey,
            borderRadius: '8px',
            flexDirection: 'column',
            gap: '10px',
          }}
        >
          {/* First line: title and content */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-grow',
              padding: '0px',
              height: '1.0em',
              gap: '20px',
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <p style={{ fontWeight: 800, fontSize: '1.0em' }}>{doc.title}</p>
            <p style={{ fontWeight: 200, fontSize: '1.0em' }}>
              {JSON.parse(doc.content)?.children
                ?.map((paragraph) => paragraph.children?.[0]?.text)
                .join(' ')
                .split(' ')
                .slice(0, 15)
                .join(' ') + ' ...' || 'No content available'}
            </p>
          </Box>

          {/* Second line: archived at and restore button */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
              height: '1.0em',
              marginTop: '10px',
              marginBottom: '0px',
            }}
          >
            <p style={{ fontSize: '0.7em' }}>
              <strong>Archived At:</strong> {new Date(doc.updated_at).toLocaleString()}
            </p>
            <Button
              onClick={() => handleRestoreDocument(doc)}
              variant="contained"
              sx={{ color: 'white', backgroundColor: notionBlack, marginTop: '0px', height: '2em', fontSize: '0.7em' }}
            >
              Restore
            </Button>
          </Box>
        </Box>
      ))}
    </Box>
  </>
)}
</>
)}  
  </Box>
  </Box>    
  </> 
  )} 

 
 
  {!ismini && (
  <>
 
  <IconButton
  onClick={() => toggleOptions()}
  variant="contained"
  sx={{
    position: 'fixed',
    borderRadius: 0,
    bottom: 0,
    right: 0,
    width: '100vw',
    height: '60px',
    zIndex: 200,
    color: 'white',
    backgroundColor: notionBlack,
    marginTop: '0px',
    marginBottom: '0px',
    marginLeft: '0px',
    '&:hover': {
      backgroundColor: '#111',
      opacity: 1,
    },
  }}
  title="Show Your Songs"
>
  <MenuIcon />
</IconButton>
{!isPWA && (
<Button
  sx={{
    position: 'fixed',
    bottom: 10,
    right: `calc(max(10px, 50% - 750px))`,
    zIndex: 2000,
    color: 'white',
    backgroundColor: notionBlack,
    marginTop: '0px',
    marginBottom: '0px',
    marginRight: '0px',
    padding: '10px',
  }}
  onClick={() => backtohome()}
>
  BACK TO THE_CIRCLE
</Button>

)}

      <Box sx={{ height:'20px' }}></Box> 
      
      </>
  )}  



    </ThemeProvider>
  );
}