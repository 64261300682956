import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const notionBlack = '#191919';
const offWhite = '#CFCFCF';
const notionGrey = '#202020';

export default function Header({ isAuthenticated, onLogout, user }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMenuAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuAnchorEl(null);
  };

  const services = [
    { name: 'THE_CLEANUP', path: '/services/vocal-cleanup' },
    { name: 'THE_TRANSCRIPT', path: '/services/the-transcript' },
    { name: 'THE_COWRITER', path: '/services/cowriter' },
    { name: 'THE_FEEDBACK', path: '/services/feedback' },
    { name: 'THE_SPLIT', path: '/services/stem-split' },
    // Add more services here as you develop them
  ];

  return (
    <>
      <AppBar position="static" sx={{ backgroundColor: notionBlack }}>
        <Toolbar sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
         
          <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/images/circlelogo_white.png"
              alt="Logo"
              style={{ width: isMobile ? '100px' : '140px', height: 'auto' }}
            />
          </Box>

      
          {!isMobile && (
            <Box sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
              <Button
                color="inherit"
                onClick={handleClick}
                sx={{
                  color: offWhite,
                  fontFamily: 'Oswald, sans-serif',
                  fontWeight: 600,
                  background: notionGrey,
                  width: '200px'
                }}
              >
                THE_TOOLS
              </Button>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    backgroundColor: notionGrey,
                    width: '200px',
                    marginTop: '8px',
                    
                  },
                }}
              >
                {services.map((service) => (
                  <MenuItem
                    key={service.path}
                    onClick={handleClose}
                    component={Link}
                    to={service.path}
                    sx={{
                      color: offWhite,
                      '&:hover': {
                        backgroundColor: 'rgba(255, 255, 255, 0.08)',
                      },
                    }}
                  >
                    {service.name}
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          )}

          {/* Mobile menu icon for small screens */}
          {isMobile && (
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMobileMenuOpen}
            >
              <MenuIcon />
            </IconButton>
          )}

          {/* Logo on the right */}
          <Box component={Link} to="/" sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              src="/pslogo.png"
              alt="Logo"
              style={{ width: isMobile ? '80px' : '100px', height: 'auto' }}
            />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Mobile menu */}
      <Menu
        anchorEl={mobileMenuAnchorEl}
        open={Boolean(mobileMenuAnchorEl)}
        onClose={handleMobileMenuClose}
        PaperProps={{
          sx: {
            backgroundColor: notionGrey,
            width: '100%',
          },
        }}
      >
      
        {services.map((service) => (
          <MenuItem
            key={service.path}
            onClick={handleMobileMenuClose}
            component={Link}
            to={service.path}
            sx={{
              color: offWhite,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.08)',
              },
            }}
          >
            {service.name}
          </MenuItem>
        ))}

<Button
            color="inherit"
            onClick={onLogout}
            sx={{
              color: offWhite,
              fontFamily: 'Oswald, sans-serif',
              fontWeight: 300,
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              padding: '2px 8px',
              minWidth: 'auto',
              background:'transparent',
              marginLeft: '10px',
              marginTop: '20px',
            }}
          >
            Logout
          </Button>
      </Menu>

      {/* User Info Overlay */}
      {isAuthenticated && !isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: { xs: 10, sm: 20 },
            left: { xs: 10, sm: 20 },
            backgroundColor: notionGrey,
            padding: { xs: '8px', sm: '10px' },
            borderRadius: '5px',
            zIndex: 1000,
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-grow',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Oswald, sans-serif',
              color: offWhite,
              fontWeight: 300,
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              marginBottom: '0px',
              marginRight: '10px',
            }}
          >
            Member: {user?.displayName || 'User'}
          </Typography>
          <Button
            color="inherit"
            onClick={onLogout}
            sx={{
              color: offWhite,
              fontFamily: 'Oswald, sans-serif',
              fontWeight: 300,
              fontSize: { xs: '0.8rem', sm: '0.9rem' },
              padding: '2px 8px',
              minWidth: 'auto',
              background:notionBlack,
              marginLeft: '0px',
            }}
          >
            Logout
          </Button>
        </Box>
      )}
    </>
  );
}