import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import axios from 'axios';
import Header from './components/Header';
import Login from './components/Login';
import VocalCleanup from './services/vocalCleanup/VocalCleanup';
import CoWriter from './services/cowriter/CoWriter';
import StemSplit from './services/stemSplit/StemSplit';
import TheTranscript from './services/theTranscript/theTranscript';
import TheFeedback from './services/feedback/feedback';
import ProtectedRoute from './components/ProtectedRoute';
import usePreventBackNavigation from './hooks/usePreventBackNavigation'
import { Grid, Card, CardActionArea, CardContent, Typography, Box} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';


const notionBlack = '#191919';
const offWhite = '#CFCFCF';

const theme = createTheme({
  palette: {
    background: {
      default: notionBlack,
    },
    text: {
      primary: offWhite,
    },
  },
  typography: {
    fontFamily: 'Oswald, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: notionBlack,
          color: offWhite,
        },
      },
    },
  },
});

const services = [
  { name: 'THE_FEEDBACK', path: '/services/feedback', image: '/images/feedback.png', description: 'Receive instant feedback on your tracks.' },
  { name: 'THE_CLEANUP', path: '/services/vocal-cleanup', image: '/images/cleanup.png', description: 'Instantly clean up your vocal recordings' },
  { name: 'THE_TRANSCRIPT', path: '/services/the-transcript', image: '/images/transcript.png', description: 'Turn audio samples into Midi Files' },
  { name: 'THE_COWRITER', path: '/services/cowriter', image: '/images/cowriter.png', description: 'Write songs like never before.' },
  { name: 'THE_SPLIT', path: '/services/stem-split', image: '/images/split.png', description: 'Split your audio into individual stems.' },
  // Add more services here as you develop them
];


function MobileApp() {

  const [selectedTab, setSelectedTab] = useState(0);

  return (

   
      <ThemeProvider theme={theme}>
      <CssBaseline />
      
      <div style={{  padding: '0rem',  minHeight: '100vh' , width: '100%' }}>


        {/* Conditionally show either TheFeedback or CoWriter */}
        <div style={{ height:'50px'}} />
        {selectedTab === 0 && <CoWriter />}
        {selectedTab === 1 && <TheFeedback />}

      </div>

   
      <Paper 
        sx={{ 
          position: 'fixed', 
          top: 0, 
          left: 0, 
          right: 0, 

        }} 
        elevation={3}
      >
        <BottomNavigation
          showLabels
          value={selectedTab}
          onChange={(event, newValue) => setSelectedTab(newValue)}
          sx={{ backgroundColor: notionBlack,  height: '50px',}}
        >

<BottomNavigationAction 
            label="THE_COWRITER" 
            sx={{ color: offWhite,
              '&.Mui-selected': {
                color: 'magenta',  // Selected tab text color
              }
            }}
          />
          <BottomNavigationAction 
            label="THE_FEEDBACK" 
            sx={{ color: offWhite,
              '&.Mui-selected': {
                color: 'magenta',  // Selected tab text color
              }
            }}
          />
     
        </BottomNavigation>
      </Paper>

    </ThemeProvider>
  
  );
  
}


function Home() {
  const navigate = useNavigate();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div style={{ textAlign: 'center', padding: '2rem' }}>
      <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '3rem', marginTop: '0rem' }}>WELCOME TO THE_CIRCLE APP</h2>

        <Grid container spacing={4} justifyContent="center">
          {services.map((service) => (
            <Grid item key={service.name} xs={12} sm={6} md={4} lg={3}>
             <Card style={{ backgroundColor: notionBlack, color: offWhite, borderRadius: '16px', overflow: 'hidden', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.7)' }}>
                <CardActionArea onClick={() => navigate(service.path)}>
                  <Box position="relative" height="300px">
                    <img
                      src={service.image}
                      alt={service.name}
                      style={{ width: '100%', height: '300px', objectFit: 'cover', borderRadius: '16px' }}
                    />
                    <Box
                      position="absolute"
                      top="0"
                      left="0"
                      right="0"
                      bottom="0"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      bgcolor="rgba(0, 0, 0, 0.5)"
                      textAlign="center"
                      sx={{
                        transition: 'background-color 0.3s ease',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)',
                        },
                      }}
                    >
                      <Typography variant="h4" style={{ fontWeight: 'bold', color: offWhite, marginBottom: '0.5rem' }}>
                        {service.name}
                      </Typography>
                      <Typography variant="body2" style={{ color: offWhite }}>
                        {service.description}
                      </Typography>
                    </Box>
                  </Box>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
       
        
        <img src="/circlecard.png" alt="Circle Card" style={{ maxWidth: '300px', height: 'auto', marginBottom: '2rem',marginTop: '2rem' }} />
      </div>
     
    </ThemeProvider>
  );
}


function AppContent() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const showHeader = location.pathname !== '/services/cowriter' && location.pathname !== '/mobileapp';

  usePreventBackNavigation();

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setIsAuthenticated(true);
      setUser(JSON.parse(storedUser));
      handleDailySubscriptionCheck();
    }
    setIsLoading(false);
  }, []);

  const handleDailySubscriptionCheck = () => {
    const lastCheck = localStorage.getItem('lastSubscriptionCheck');
    const now = new Date().getTime();

    // If no last check or more than 24 hours have passed, perform a new check
    if (!lastCheck || now - parseInt(lastCheck, 10) > 24 * 60 * 60 * 1000) {
      checkSubscription();
      localStorage.setItem('lastSubscriptionCheck', now.toString());
    }
  };

  const checkSubscription = async () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const { email } = JSON.parse(storedUser);
      try {
        const response = await axios.get(`https://api.philspeiser.com/checkSubsForApp?email=${email}`);
        console.log('Subscription check response:', response);
        if (response.data !== 1) {
          handleLogout();
        }
      } catch (error) {
        console.error('Error checking subscription:', error);
      }
    }
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setIsAuthenticated(false);
    setUser(null);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        minHeight: '100vh',
        backgroundColor: notionBlack,
        color: offWhite,
        fontFamily: 'Oswald, sans-serif',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {showHeader && (
        <Header isAuthenticated={isAuthenticated} onLogout={handleLogout} user={user} />
      )}
      
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '1500px',
          margin: '0 auto',
          py: { xs: '0rem', sm: '0rem' }, // Responsive vertical padding
          px: { xs: '0.0rem', sm: '0rem' }, // Responsive horizontal padding
        }}
      >
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route
            path="/login"
            element={
              isAuthenticated ? <Navigate to="/" replace /> : <Login onLogin={handleLogin} />
            }
          />
          <Route
    path="/mobileapp"
    element={
      <ProtectedRoute isAuthenticated={isAuthenticated}>
        <MobileApp />
      </ProtectedRoute>
    }
  />
          <Route
            path="/services/vocal-cleanup"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Box sx={{ width: '100%' }}>
                  <VocalCleanup />
                </Box>
              </ProtectedRoute>
            }
          />
          <Route
            path="/services/the-transcript"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Box sx={{ width: '100%' }}>
                  <TheTranscript />
                </Box>
              </ProtectedRoute>
            }
          />
          <Route
            path="/services/cowriter"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Box sx={{ width: '100%' }}>
                  <CoWriter/>
                </Box>
              </ProtectedRoute>
            }
          />
          <Route
            path="/services/feedback"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Box sx={{ width: '100%' }}>
                  <TheFeedback />
                </Box>
              </ProtectedRoute>
            }
          />
          <Route
            path="/services/stem-split"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Box sx={{ width: '100%' }}>
                  <StemSplit />
                </Box>
              </ProtectedRoute>
            }
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <AppContent />
      </Router>
    </ThemeProvider>
  );
}