'use client'
import React, { useState, useEffect, useRef } from 'react'
import { Button, TextField, Typography, Box, Paper, CircularProgress } from '@mui/material'
import { Send as SendIcon } from '@mui/icons-material'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism';

const notionBlack = '#191919'
const notionGrey = '#202020'
const offWhite = '#CFCFCF'
const magenta = '#FF00FF'

export default function TheChatbot({ fileUri, analysisResults }) {
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const messagesEndRef = useRef(null)
  const lastMessageRef = useRef(null)

  const scrollToLastMessage = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  useEffect(scrollToLastMessage, [messages])

  useEffect(() => {
    // Initialize ONLY when fileUri and analysisResults are available.
    if (fileUri && analysisResults) {
      const user = JSON.parse(localStorage.getItem('user') || '{"displayName": "there"}');
      const displayName = user?.displayName || 'there';
      const firstName = displayName.split(' ')[0];

      const initialMessage = {
        role: 'assistant',
        type: 'text',
        content: `Hey ${firstName},\nI finished listening to your song. Let's talk about it!\nWhat do you want to discuss about it?`
      };

      setMessages([initialMessage]);
    }
  }, [fileUri, analysisResults]); // Depend on fileUri and analysisResults

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!input.trim()) return;

    const userMessage = { role: 'user', type: 'text', content: input };
    setMessages((prev) => [...prev, userMessage]);
    setInput('');
    setIsLoading(true);

    try {
      // Format message history correctly for the API
      const messageHistory = messages
        .filter((message) => message.role === 'user' || message.role === 'assistant')
        .map((message) => ({
          role: message.role === 'assistant' ? 'model' : 'user', // Convert 'assistant' to 'model' as expected by Gemini
          text: message.content // Server expects 'text', not 'content'
        }));

      // Get user data; handle potential missing data
      const userString = localStorage.getItem('user');
      let firstName = 'User'; // Default value
      if (userString) {
        try {
          const user = JSON.parse(userString);
          firstName = user.displayName?.split(' ')[0] || 'User';
        } catch (parseError) {
          console.error("Error parsing user data:", parseError);
          // Use default firstName
        }
      }

      // Create payload
      const payload = {
        user_query: input,
        uri: fileUri,
        history: messageHistory,
        user_name: firstName,
      };

      // Only add analysis_results if it exists
      if (analysisResults) {
        // If analysisResults is already a string, use it directly
        // Otherwise, stringify it
        if (typeof analysisResults === 'string') {
          payload.analysis_results = analysisResults;
        } else {
          payload.analysis_results = JSON.stringify(analysisResults);
        }
      }

      console.log("Sending payload:", JSON.stringify(payload, null, 2));

      const response = await fetch('https://think.philspeiser.com/gfeedbackchat/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error(`HTTP error! status: ${response.status}, details:`, errorText);
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = { role: 'assistant', type: 'text', content: '' };
      setMessages((prev) => [...prev, assistantMessage]); // Add new message *before* streaming
      let fullContent = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
   

        // Handle each line separately (server sends newline-delimited JSON)
        const lines = chunk.split('\n').filter(line => line.trim());
        
        for (const line of lines) {
          try {
            const jsonObject = JSON.parse(line);
            if (jsonObject.response) {
              fullContent += jsonObject.response;
              setMessages((prevMessages) => {
                const newMessages = [...prevMessages];
                newMessages[newMessages.length - 1] = { 
                  role: 'assistant', 
                  type: 'text', 
                  content: fullContent 
                };
                return newMessages;
              });
            } else if (jsonObject.error) {
              console.error("Server returned error:", jsonObject.error);
              throw new Error(jsonObject.error);
            }
          } catch (e) {
            console.warn("Failed to parse JSON from line:", line, e);
            // Continue to next line if this one fails
          }
        }
      }

    } catch (error) {
      console.error('Error:', error);
      const errorMessage = 'Sorry, there was an error processing your request.';
      setMessages((prev) => [...prev, { role: 'assistant', type: 'text', content: errorMessage }]);
    } finally {
      setIsLoading(false);
    }
  };

  const renderMessage = (message, index, isLastMessage) => {
    const messageContent = (
      <Box
        ref={isLastMessage ? lastMessageRef : null}
        key={index}
        sx={{
          mb: 2,
          textAlign: message.role === 'user' ? 'right' : 'left',
        }}
      >
        <Box
          sx={{
            display: 'inline-block',
            p: 1,
            borderRadius: 1,
            backgroundColor: message.role === 'user' ? notionGrey : 'secondary.main',
            color: 'text.primary',
          }}
        >
          <ReactMarkdown
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '')
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={darcula}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={className} {...props}>
                    {children}
                  </code>
                )
              },
            }}
          >
            {message.content}
          </ReactMarkdown>
        </Box>
      </Box>
    );

    return messageContent;
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%', // Take full height of parent container
      maxWidth: '4xl',
      margin: 'auto',
      p: 2,
    }}>
      <Paper
        elevation={0}
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          p: 2,
          mb: 2,
          backgroundColor: '#191919',
        }}
      >
        {messages.map((message, index) =>
          renderMessage(message, index, index === messages.length - 1)
        )}
        <div ref={messagesEndRef} />
      </Paper>
      <Box sx={{ position: 'sticky', bottom: 0, backgroundColor: 'background.default', pt: 2 }}>
        <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '8px' }}>
          <TextField
            fullWidth
            variant="outlined"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message here..."
            disabled={isLoading}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{
              backgroundColor: 'background.paper',
              color: 'text.primary',
              '&:hover': {
                backgroundColor: '#2c2c2c',
              },
            }}
          >
            {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
          </Button>
        </form>
      </Box>
    </Box>
  )
}