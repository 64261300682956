'use client'

import React, { useEffect } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Backdrop,
  LinearProgress,
  Snackbar,
  Alert,
  Paper,
  Grid,
  useMediaQuery,
} from '@mui/material'
import { CloudUpload, MusicNote, Headphones } from '@mui/icons-material'
import { Refresh as RefreshIcon } from '@mui/icons-material';
import '@fontsource/oswald'
import TheChatbot from './TheCB'
import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import AudioRecorder from './AudioRecorder';

const notionBlack = '#191919'
const notionGrey = '#202020'
const offWhite = '#CFCFCF'
const magenta = '#FF00FF'

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: offWhite,
    },
    secondary: {
      main: notionBlack,
    },
    background: {
      default: notionBlack,
      paper: notionGrey,
    },
    text: {
      primary: offWhite,
      secondary: offWhite,
    },
  },
  typography: {
    fontFamily: 'Oswald, Arial, sans-serif',
    h4: {
      fontWeight: 500,
      color: offWhite,
    },
    h6: {
      fontWeight: 400,
      color: offWhite,
    },
    button: {
      fontWeight: 500,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: offWhite,
          backgroundColor: notionGrey,
          '&:hover': {
            backgroundColor: '#2c2c2c',
          },
          '&.MuiButton-containedSecondary': {
            backgroundColor: notionBlack,
            color: offWhite,
            '&:hover': {
              backgroundColor: magenta,
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          color: offWhite,
        },
      },
    },
  },
})

const API_BASE_URL = 'https://think.philspeiser.com/gfeedback'; // API base URL
const MAX_FILE_SIZE = 80 * 1024 * 1024; // 80MB in bytes

export default function TheFeedback() {
  const [audioFile, setAudioFile] = React.useState(null)
  const [status, setStatus] = React.useState('idle') // 'idle', 'converting', 'uploading', 'processing', 'analyzing', 'complete'
  const [uploadProgress, setUploadProgress] = React.useState(0)
  const [toast, setToast] = React.useState(null)
  const [errorMessage, setErrorMessage] = React.useState(null)
  const fileInputRef = React.useRef(null)
  const [analysisResults, setAnalysisResults] = React.useState(null); // Store analysis
  const [fileUri, setFileUri] = React.useState(null); // Store the file URI
  const [processingMessage, setProcessingMessage] = React.useState(''); // Dynamic processing message
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const [conversionProgress, setConversionProgress] = React.useState(0);
 

 



  // Array of processing messages to cycle through
  const processingMessages = [
    "Converting your audio to MP3 format...",
    "Uploading your track...",
    "Analyzing audio characteristics...",
    "Identifying key and tempo...",
    "Examining musical structure...",
    "Phil is listening to your track...",
    "Preparing feedback...",
    "Almost there! Just a few more seconds..."
  ];


  const triggerFileInputForDevice = () => {
    // Check if the device is iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
    // Check if the device is mobile (including Android)
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    if (isIOS || (isMobile && status === 'idle')) {
      // For mobile devices, use the special handling
      const tempInput = document.createElement('input');
      tempInput.type = 'file';
      tempInput.accept = 'audio/mp3,audio/wav,audio/ogg,audio/m4a,audio/aac,audio/flac,audio/*';
      tempInput.style.position = 'fixed';
      tempInput.style.top = '0';
      tempInput.style.opacity = '0.01';
      document.body.appendChild(tempInput);
      
      // Add event listener to handle file selection
      tempInput.addEventListener('change', (event) => {
        if (event.target.files && event.target.files[0]) {
          // Call the same handler with the temporary input's event
          handleFileUpload(event);
          // Remove the temporary input after use
          document.body.removeChild(tempInput);
        }
      });
      
      // Add cleanup in case user cancels
      tempInput.addEventListener('blur', () => {
        setTimeout(() => {
          if (document.body.contains(tempInput)) {
            document.body.removeChild(tempInput);
          }
        }, 500);
      });
      
      // Trigger the file dialog
      tempInput.click();
    } else {
      // For desktop, use the original method
      triggerFileInput();
    }
  };

  // Function to analyze audio metrics locally using Web Audio API
  const analyzeAudioMetrics = async (audioFile) => {
    // Create a promise that resolves with the audio metrics
    return new Promise((resolve, reject) => {
      try {
        // Create an audio context
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        const audioContext = new AudioContext();
        
        // Create a file reader to read the audio file
        const reader = new FileReader();
        
        // When file is loaded, decode the audio data
        reader.onload = async (e) => {
          try {
            // Decode the audio data
            const audioBuffer = await audioContext.decodeAudioData(e.target.result);
            
            // Process the audio data to analyze metrics
            const metrics = processAudioBuffer(audioBuffer);
            
            // Close the audio context when done
            audioContext.close();
            
            // Resolve with the metrics
            resolve(metrics);
          } catch (decodeError) {
            console.error('Error decoding audio data:', decodeError);
            reject(decodeError);
          }
        };
        
        // Error handling for file reader
        reader.onerror = (error) => {
          console.error('Error reading file:', error);
          reject(error);
        };
        
        // Read the file as an array buffer
        reader.readAsArrayBuffer(audioFile);
      } catch (error) {
        console.error('Error initializing audio analysis:', error);
        reject(error);
      }
    });
  };

  // Process the audio buffer to calculate various metrics
  const processAudioBuffer = (audioBuffer) => {
    // Get basic audio properties
    const sampleRate = audioBuffer.sampleRate;
    const duration = audioBuffer.duration;
    const numberOfChannels = audioBuffer.numberOfChannels;
    
    // Initialize metrics object
    const metrics = {
      duration: duration.toFixed(2),
      sampleRate: sampleRate,
      numberOfChannels: numberOfChannels,
      peakAmplitude: 0,
      truePeak: 0,
      rms: 0,
      crestFactor: 0,
      estimatedLUFS: 0,
      perceivedLoudness: 0
    };
    
    // Arrays to store metrics per channel
    const peakPerChannel = [];
    const rmsPerChannel = [];
    
    // Process each channel
    for (let channel = 0; channel < numberOfChannels; channel++) {
      // Get the audio data for this channel
      const channelData = audioBuffer.getChannelData(channel);
      
      // Calculate peak amplitude (maximum absolute value)
      let peak = 0;
      
      // Calculate RMS (root mean square)
      let sumOfSquares = 0;
      
      // Process each sample
      for (let i = 0; i < channelData.length; i++) {
        const absoluteSample = Math.abs(channelData[i]);
        
        // Update peak if this sample is louder
        if (absoluteSample > peak) {
          peak = absoluteSample;
        }
        
        // Add to sum of squares for RMS calculation
        sumOfSquares += channelData[i] * channelData[i];
      }
      
      // Calculate RMS for this channel
      const rms = Math.sqrt(sumOfSquares / channelData.length);
      
      // Store metrics for this channel
      peakPerChannel.push(peak);
      rmsPerChannel.push(rms);
    }
    
    // Get the highest peak across all channels
    metrics.peakAmplitude = Math.max(...peakPerChannel);
    
    // Convert peak amplitude to dBFS (decibels relative to full scale)
    metrics.peakAmplitudeDB = 20 * Math.log10(metrics.peakAmplitude).toFixed(2);
    
    // Use the average RMS across all channels
    metrics.rms = rmsPerChannel.reduce((sum, val) => sum + val, 0) / numberOfChannels;
    
    // Convert RMS to dBFS
    metrics.rmsDB = 20 * Math.log10(metrics.rms).toFixed(2);
    
    // Calculate crest factor (peak to RMS ratio)
    metrics.crestFactor = (metrics.peakAmplitude / metrics.rms).toFixed(2);
    
    // Estimate LUFS (this is a basic estimation)
    // True LUFS calculation requires frequency weighting and gating
    metrics.estimatedLUFS = (metrics.rmsDB - 0.691).toFixed(2); // Very basic approximation
    
    // Estimate true peak (this is a simplified estimation)
    // True peak detection requires oversampling and more complex processing
    metrics.truePeak = (metrics.peakAmplitudeDB + 0.5).toFixed(2); // Simple approximation
    
    // Return the calculated metrics
    return metrics;
  };

  // Calculate dynamic range (simplified version)
  const calculateDynamicRange = (audioBuffer) => {
    try {
      const channelData = audioBuffer.getChannelData(0); // Using first channel for simplicity
      
      // Split audio into segments
      const segmentSize = Math.floor(audioBuffer.sampleRate * 3); // 3-second segments
      const segments = Math.floor(channelData.length / segmentSize);
      
      const segmentPeaks = [];
      
      // Find peak in each segment
      for (let i = 0; i < segments; i++) {
        const start = i * segmentSize;
        const end = start + segmentSize;
        
        let segmentPeak = 0;
        for (let j = start; j < end && j < channelData.length; j++) {
          const abs = Math.abs(channelData[j]);
          if (abs > segmentPeak) {
            segmentPeak = abs;
          }
        }
        
        segmentPeaks.push(segmentPeak);
      }
      
      // Sort peaks to find the difference between loud and quiet parts
      segmentPeaks.sort((a, b) => a - b);
      
      // Calculate DR as difference between 95th percentile and 20th percentile
      const loudIndex = Math.floor(segmentPeaks.length * 0.95);
      const quietIndex = Math.floor(segmentPeaks.length * 0.2);
      
      if (loudIndex >= segmentPeaks.length || quietIndex >= segmentPeaks.length || segmentPeaks[quietIndex] === 0) {
        return 0;
      }
      
      const loudPart = segmentPeaks[loudIndex];
      const quietPart = segmentPeaks[quietIndex];
      
      // Calculate dynamic range in dB
      return (20 * Math.log10(loudPart / quietPart)).toFixed(2);
    } catch (error) {
      console.error('Error calculating dynamic range:', error);
      return 0;
    }
  };

  // Cycle through processing messages
  React.useEffect(() => {
    if (status === 'processing' || status === 'analyzing') {
      const messageInterval = setInterval(() => {
        setProcessingMessage(prevMessage => {
          const currentIndex = processingMessages.indexOf(prevMessage);
          const nextIndex = (currentIndex + 1) % processingMessages.length;
          return processingMessages[nextIndex];
        });
      }, 3000);
      
      // Set initial message
      if (!processingMessage) {
        setProcessingMessage(processingMessages[0]);
      }
      
      return () => clearInterval(messageInterval);
    }
  }, [status, processingMessage]);

  const validateFile = (file) => {
    if (!file) {
      setErrorMessage('Please select a file.');
      return false;
    }

    const fileName = file.name.toLowerCase();
    const fileExtension = fileName.split('.').pop();
    const isAudio = ['mp3', 'wav', 'ogg', 'm4a', 'flac', 'aac'].includes(fileExtension);

    if (!isAudio) {
      setErrorMessage('Please upload an audio file (MP3, WAV, OGG, M4A, FLAC, AAC).');
      return false;
    }

    if (file.size > MAX_FILE_SIZE) {
      setErrorMessage('File size exceeds 80MB limit. Please choose a smaller file.');
      return false;
    }

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = function(e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 12);
        let header = "";
        for(let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }

        // Basic header check for common audio formats
        const mp3Headers = ['4944', 'fffb'];
        const wavHeaders = ['52494646']; // RIFF
        const oggHeaders = ['4f676753']; // OggS
        const flacHeaders = ['664c6143']; // fLaC
        
        const isValidAudio = 
          (fileExtension === 'mp3' && mp3Headers.some(h => header.startsWith(h))) ||
          (fileExtension === 'wav' && wavHeaders.some(h => header.startsWith(h))) ||
          (fileExtension === 'ogg' && oggHeaders.some(h => header.startsWith(h))) ||
          (fileExtension === 'flac' && flacHeaders.some(h => header.startsWith(h))) ||
          // For formats without simple header checks, trust the file extension
          ['m4a', 'aac'].includes(fileExtension);

        if (isValidAudio) {
          resolve(true);
        } else {
          setErrorMessage('The file appears to be corrupted or is not a valid audio file.');
          resolve(false);
        }
      };

      reader.onerror = function() {
        setErrorMessage('Error reading the file. It may be corrupted.');
        resolve(false);
      };

      reader.readAsArrayBuffer(file);
    });
  };


 // Improved handleRecordedAudio function to create a more robust File object
const handleRecordedAudio = async (recordedBlob) => {
  try {
    // Create a proper File object from the Blob with all necessary properties
    const recordedFile = new File(
      [recordedBlob], 
      'recorded-audio.webm', 
      { 
        type: 'audio/webm',
        lastModified: new Date().getTime() // Add lastModified timestamp
      }
    );
    
    // Set the file in state
    setAudioFile(recordedFile);
    
    // Display success message
    setToast({ 
      severity: 'success', 
      message: 'Recording saved successfully. Click "Start Analysis" to proceed.' 
    });
    
    // For mobile, automatically trigger analysis after a short delay
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
    
    if (isMobile) {
      // Set status to idle first to allow the button to become enabled
      setStatus('idle');
      
      // Small delay to ensure state has updated
      setTimeout(() => {
        handleAnalysis(recordedFile); // Pass the file directly
      }, 500);
    }
  } catch (error) {
    console.error('Error processing recorded audio:', error);
    setToast({ 
      severity: 'error', 
      message: 'Error processing recording. Please try again.' 
    });
  }
};

  // Function to convert audio to MP3 using FFmpeg
  const convertToMp3 = async (audioFile) => {
    const fileName = audioFile.name.toLowerCase();
    const fileExtension = fileName.split('.').pop();
    
    // If already MP3, no conversion needed
    if (fileExtension === 'mp3') {
      return audioFile;
    }
    
    setStatus('converting');
    setProcessingMessage('Converting audio to MP3 format...');
    
    try {
      // Create FFmpeg instance if needed
      const ffmpeg = new FFmpeg();
      
      // Load FFmpeg (if not already loaded)
      if (!ffmpeg.loaded) {
        setProcessingMessage("Loading audio conversion tools...");
        await ffmpeg.load();
      }
      
      // Get appropriate input name based on file type
      const inputName = 'input' + (fileExtension === 'wav' ? '.wav' : '.' + fileExtension);
      const outputName = 'output.mp3';
      
      // Track progress
      ffmpeg.on('progress', ({ progress }) => {
        if (progress) {
          setConversionProgress(Math.floor(progress * 100));
        }
      });
      
      // Write input file to FFmpeg's file system
      await ffmpeg.writeFile(inputName, await fetchFile(audioFile));
      
      // Run FFmpeg command to convert to MP3
      await ffmpeg.exec([
        '-i', inputName, 
        '-acodec', 'libmp3lame', 
        '-b:a', '128k', 
        outputName
      ]);
      
      // Read the output file
      const data = await ffmpeg.readFile(outputName);
      
      // Create MP3 blob and file
      const mp3Blob = new Blob([data.buffer], { type: 'audio/mp3' });
      const mp3File = new File(
        [mp3Blob], 
        audioFile.name.replace(/\.[^/.]+$/, '.mp3'), 
        { type: 'audio/mp3' }
      );
      
      // Clean up (delete input and output files)
      try {
        await ffmpeg.deleteFile(inputName);
        await ffmpeg.deleteFile(outputName);
      } catch (cleanupError) {
        console.error('Error cleaning up FFmpeg files:', cleanupError);
        // Continue even if cleanup fails
      }
      
      setToast({ severity: 'success', message: 'Audio successfully converted to MP3.' });
      return mp3File;
    } catch (error) {
      console.error('Error converting to MP3:', error);
      setToast({ 
        severity: 'warning', 
        message: 'Error converting to MP3. Using original file instead.' 
      });
      return audioFile;
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click()
  }

  const handleReload = () => {
     setAudioFile(null);
     setStatus('idle');
     setUploadProgress(0);
     setConversionProgress(0);
     setToast(null);
     setErrorMessage(null);
     setAnalysisResults(null);
     setFileUri(null);
     setProcessingMessage('');
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    setErrorMessage(null); // Clear previous errors
  
    if (file) {
      // Add a validation status to show feedback
      setStatus('validating');
      
      try {
        const isValid = await validateFile(file);
        if (isValid) {
          setAudioFile(file);
          setToast({ severity: 'success', message: 'File validated successfully.' });
          
          // For mobile devices, automatically trigger analysis
          const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
          if (isMobile) {
            // Small timeout to ensure state updates have completed
            setTimeout(() => {
              handleAnalysis(file); // Pass the file directly
            }, 300);
          } else {
            // On desktop, reset status to idle (keep current behavior)
            setStatus('idle');
          }
        } else {
          setStatus('idle');
        }
      } catch (error) {
        console.error('File validation error:', error);
        setStatus('idle');
      }
    }
  };

  const handleAnalysis = async (directFile = null) => {
    // Use either the passed file or the one from state
    const fileToProcess = directFile || audioFile;
  
    if (!fileToProcess) {
      setToast({ severity: 'error', message: 'Please select an audio file first.' });
      return;
    }
  
    // Common resets before we dive into conversion/upload logic
    setUploadProgress(0);
    setConversionProgress(0);
    setAnalysisResults(null);
    setFileUri(null);
  
    try {
      // Check whether device is mobile
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    
      if (isMobile) {
        // ----- MOBILE LOGIC -----
        // Check if the file is valid
        if (!fileToProcess || !(fileToProcess instanceof File || fileToProcess instanceof Blob)) {
          setToast({ severity: 'error', message: 'No valid file provided. Please try again.' });
          setStatus('idle');
          return;
        }
    
        // Ensure we have a name property for the file
        const fileName = fileToProcess.name || 'recorded-audio.webm';
        const fileExtension = fileName.split('.').pop().toLowerCase();
    
        let fileToUpload;
  
        if (fileExtension !== 'mp3') {
          setStatus('converting');
          setProcessingMessage('Converting to MP3 format...');
  
          // Only attempt conversion if file is not too large (to avoid memory issues)
          if (fileToProcess.size > 30 * 1024 * 1024) {
            // 30MB limit for conversion
            setToast({
              severity: 'warning',
              message: 'File too large for browser conversion. Using original format.',
            });
            fileToUpload = fileToProcess;
          } else {
            try {
              fileToUpload = await convertToMp3(fileToProcess);
            } catch (conversionError) {
              console.error('Conversion failed:', conversionError);
              setToast({
                severity: 'error',
                message: 'Conversion to MP3 failed. Please reload the page and try again.',
              });
              
              // Reset status to idle
              setStatus('idle');
              return; // Exit the function early - prevents upload
            }
          }
        } else {
          fileToUpload = fileToProcess;
        }
  
        setStatus('uploading');
        setProcessingMessage('Uploading your track...');
  
        const formData = new FormData();
        formData.append('file', fileToUpload);
  
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_BASE_URL}/upload_audio`);
  
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            setUploadProgress(percentCompleted);
          }
        };
  
        xhr.upload.onload = () => {
          setStatus('processing');
          setProcessingMessage(processingMessages[2]); // Analyzing audio characteristics...
        };
  
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 3) {
            setStatus('analyzing');
            setProcessingMessage(processingMessages[5]); // Phil is listening to your track...
          }
        };
  
        xhr.onload = () => {
          if (xhr.status === 200 || xhr.status === 201) {
            try {
              const result = JSON.parse(xhr.responseText);
              if (result.uri && result.analysis) {
                console.log('Analysis results:', result.analysis);
                console.log('URI:', result.uri);
                setAnalysisResults(result.analysis);
                setFileUri(result.uri);
  
                setProcessingMessage(processingMessages[7]); // Almost there! ...
                setTimeout(() => {
                  setStatus('complete');
                  setToast({
                    severity: 'success',
                    message: 'Analysis complete! Phil is ready to chat about your track.',
                  });
                }, 1500);
              } else {
                throw new Error('Invalid response format from the server.');
              }
            } catch (error) {
              console.error('Error parsing response:', error);
              setToast({ severity: 'error', message: 'Error processing server response.' });
              setStatus('idle');
            }
          } else {
            console.error('Server error:', xhr.status, xhr.statusText);
            setToast({ severity: 'error', message: `Server error: ${xhr.status} ${xhr.statusText}` });
            setStatus('idle');
          }
        };
  
        xhr.onerror = () => {
          console.error('Network error occurred during analysis');
          setToast({ severity: 'error', message: 'Network error occurred during analysis.' });
          setStatus('idle');
        };
  
        xhr.send(formData);
  
      } else {
        // ----- DESKTOP LOGIC -----
        // EXACTLY your last working version (references 'audioFile')
        const fileName = audioFile.name.toLowerCase();
        const fileExtension = fileName.split('.').pop();
  
        let fileToUpload;
        if (fileExtension !== 'mp3') {
          setStatus('converting');
          setProcessingMessage('Converting to MP3 format...');
  
          if (audioFile.size > 30 * 1024 * 1024) {
            setToast({
              severity: 'warning',
              message: 'File too large for browser conversion. Using original format.',
            });
            fileToUpload = audioFile;
          } else {
            try {
              fileToUpload = await convertToMp3(audioFile);
            } catch (conversionError) {
              console.error('Conversion failed:', conversionError);
              setToast({
                severity: 'warning',
                message: 'Conversion failed. Using original file.',
              });
              fileToUpload = audioFile;
            }
          }
        } else {
          fileToUpload = audioFile;
        }
  
        setStatus('uploading');
        setProcessingMessage('Uploading your track...');
  
        const formData = new FormData();
        formData.append('file', fileToUpload);
  
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_BASE_URL}/upload_audio`);
  
        xhr.upload.onprogress = (event) => {
          if (event.lengthComputable) {
            const percentCompleted = Math.round((event.loaded * 100) / event.total);
            setUploadProgress(percentCompleted);
          }
        };
  
        xhr.upload.onload = () => {
          setStatus('processing');
          setProcessingMessage(processingMessages[2]); // Analyzing audio characteristics...
        };
  
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 3) {
            setStatus('analyzing');
            setProcessingMessage(processingMessages[5]); // Phil is listening to your track...
          }
        };
  
        xhr.onload = () => {
          if (xhr.status === 200 || xhr.status === 201) {
            try {
              const result = JSON.parse(xhr.responseText);
              if (result.uri && result.analysis) {
                console.log('Analysis results:', result.analysis);
                console.log('URI:', result.uri);
                setAnalysisResults(result.analysis);
                setFileUri(result.uri);
  
                setProcessingMessage(processingMessages[7]); // Almost there! ...
                setTimeout(() => {
                  setStatus('complete');
                  setToast({
                    severity: 'success',
                    message: 'Analysis complete! Phil is ready to chat about your track.',
                  });
                }, 1500);
              } else {
                throw new Error('Invalid response format from the server.');
              }
            } catch (error) {
              console.error('Error parsing response:', error);
              setToast({ severity: 'error', message: 'Error processing server response.' });
              setStatus('idle');
            }
          } else {
            console.error('Server error:', xhr.status, xhr.statusText);
            setToast({ severity: 'error', message: `Server error: ${xhr.status} ${xhr.statusText}` });
            setStatus('idle');
          }
        };
  
        xhr.onerror = () => {
          console.error('Network error occurred during analysis');
          setToast({ severity: 'error', message: 'Network error occurred during analysis.' });
          setStatus('idle');
        };
  
        xhr.send(formData);
      }
  
    } catch (error) {
      console.error('Error:', error);
      setToast({ severity: 'error', message: error.message || 'An error occurred during analysis.' });
      setStatus('idle');
    }
  };
  

  // Upload Section - only displayed during idle, converting, and uploading states
  const UploadSection = () => (
    <>
      <Card sx={{ backgroundColor: 'background.paper', mb: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: isSmallScreen ? 'stretch' : 'center', gap: 2 }}>
            <Typography
              variant="body1"
              sx={{
                flexShrink: 1,
                minWidth: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {audioFile ? `Loaded: ${audioFile.name}` : 'No audio file selected'}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<CloudUpload />}
              onClick={triggerFileInputForDevice}
              disabled={status === 'converting' || status === 'uploading' || status === 'processing' || status === 'analyzing'}
              sx={{
                backgroundColor: notionBlack,
                color: magenta,
                '&:hover': {
                  backgroundColor: '#2c2c2c',
                },
                whiteSpace: 'nowrap',
                flexShrink: 0,
                width: isSmallScreen ? '100%' : 'auto',
              }}
            >
              {audioFile ? 'REPLACE AUDIO' : 'LOAD AUDIO'}
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              accept="audio/*" // Accept all audio formats
              style={{ display: 'none' }}
              onChange={handleFileUpload}
            />
           
           <AudioRecorder onAudioRecorded={handleRecordedAudio} />

          </Box>

         
          
          {errorMessage && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {errorMessage}
            </Alert>
          )}
          
          {/* Show progress bars during uploading and converting phases */}
          {(status === 'converting' || status === 'uploading') && (
            <Box sx={{ width: '100%', mt: 3 }}>
              <LinearProgress 
                variant="determinate" 
                value={status === 'converting' ? conversionProgress : uploadProgress} 
                sx={{ 
                  height: 10, 
                  borderRadius: 5,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: magenta
                  }
                }} 
              />
              <Typography variant="body1" color="text.secondary" align="center" sx={{ mt: 1, fontWeight: 'bold' }}>
                {status === 'converting' 
                  ? `Converting: ${conversionProgress}%` 
                  : `Uploading: ${uploadProgress}%`}
              </Typography>
              <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 1 }}>
                {status === 'converting' 
                  ? 'Converting your audio to MP3 format...' 
                  : 'Uploading your track...'}
              </Typography>
            </Box>
          )}

        </CardContent>
        

      </Card>

      <Typography variant="body1" color="text.secondary" align="left" sx={{ mt: 0, fontWeight: '100', fontSize: '0.8rem', padding: '10px'}}>
      MODEL VERSION: 2.0
      </Typography>


      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
        <Button
          variant="contained"
          onClick={handleAnalysis}
          disabled={!audioFile || status === 'converting' || status === 'uploading' || status === 'processing' || status === 'analyzing'}
          sx={{
            backgroundColor: notionGrey,
            padding: '18px 30px',
            fontSize: '1.1rem',
            fontWeight: 700,
            color: offWhite,
            '&:hover': {
              backgroundColor: '#3c3c3c',
            },
            '&:disabled': {
              backgroundColor: 'transparent',
              color: 'transparent',
            },
          }}
        >
          {status === 'converting' || status === 'uploading' || status === 'processing' || status === 'analyzing' ? 'Processing...' : 'Start Analysis'}
        </Button>
      </Box>
    </>
  );

  // Processing visualization component
  const ProcessingVisual = () => (
    <Box sx={{ 
      textAlign: 'center', 
      my: 6,
      px: 3,
      py: 6,
      backgroundColor: 'rgba(0,0,0,0.3)', 
      borderRadius: 4,
      maxWidth: '600px',
      margin: '40px auto'
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
        <Headphones sx={{ fontSize: 60, color: magenta, mb: 2 }} />
      </Box>
      
      <Typography variant="h5" sx={{ mb: 3, color: offWhite }}>
        {processingMessage}
      </Typography>
      
      <CircularProgress 
        size={80} 
        thickness={4} 
        sx={{ 
          color: magenta, 
          '& .MuiCircularProgress-circle': {
            strokeLinecap: 'round',
          }
        }} 
      />
      
      <Typography variant="body1" sx={{ mt: 4, color: offWhite, opacity: 0.7 }}>
        This may take a minute or two depending on your file size.
      </Typography>
      
      <Typography variant="body2" sx={{ mt: 2, color: offWhite, opacity: 0.5 }}>
        {status === 'analyzing' ? 'Phil is giving your track his full attention!' : 'Please wait while we process your audio.'}
      </Typography>
    </Box>
  );

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Box 
        sx={{ 
          maxWidth: '4xl', 
          margin: 'auto', 
          pt: 4, 
          pb: 4, 
          pl: { xs: 0.5, sm: 4 }, 
          pr: { xs: 0.5, sm: 4 } 
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, flexGrow: 1 }}>
            <Typography variant="h4" sx={{pl: { xs: 2, sm: 0 }, pr: { xs: 2, sm: 0 } }}>
              THE_FEEDBACK
            </Typography>
          </Box>
          {status === 'complete' && (
            <Button
              variant="contained"
              color="primary"
              startIcon={<RefreshIcon />}
              onClick={handleReload}
              sx={{
                backgroundColor: '#2c2c2c',
                '&:hover': {
                  backgroundColor: magenta,
                },
                marginRight: '10px',
              }}
            >
              START OVER
            </Button>
          )}
        </Box>
        
        {/* Main content area */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Show upload section during idle, converting, uploading but not during processing/analyzing */}
          {(status === 'idle' || status === 'converting' || status === 'uploading') && <UploadSection />}
          
          {/* Show processing visualization INSTEAD OF upload section during processing/analyzing */}
          {(status === 'processing' || status === 'analyzing') && <ProcessingVisual />}
        </Box>

        {/* Toast notifications */}
        <Snackbar 
          open={!!toast} 
          autoHideDuration={6000} 
          onClose={() => setToast(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert 
            onClose={() => setToast(null)} 
            severity={toast?.severity || "success"} 
            sx={{ 
              width: '100%', 
              fontWeight: 'bold',
              '& .MuiAlert-icon': {
                fontSize: '1.2rem'
              }
            }}
          >
            {toast?.message}
          </Alert>
        </Snackbar>

        {/* Chatbot when complete */}
        {status === 'complete' && (
          <Box 
            sx={{ 
              minHeight: '300px', 
              height: isSmallScreen ? 'calc(100vh - 250px)' : 'calc(100vh - 300px)',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: '0 4px 20px rgba(0,0,0,0.3)'
            }}
          >
            <TheChatbot fileUri={fileUri} analysisResults={analysisResults} />
          </Box>
        )}
        <Box sx={{ height: '50px' }} />
      </Box>
    </ThemeProvider>
  )
}